import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./views/Home";
import Proyects from "./views/Proyects";
import Services from "./views/Services";
import Team from "./views/Team";
import ContactUs from "./views/ContactUs";

function App() {
  return (
    <React.Fragment>
      <Header />
      <div id="content">
        <Home />
        <Proyects />
        <Services />
        <Team />
        <ContactUs />
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default App;
