import React from "react";
import "./styles.scss";

import AppIcon from "../../../assets/icons/app.png";
import CloudIcon from "../../../assets/icons/cloud.png";
import TeamIcon from "../../../assets/icons/team.png";
import WebIcon from "../../../assets/icons/web.png";

const Services = () => {
  return (
    <div
      className="pb-4 p-0 cont-services pt-4 adaptable-container"
      id="servicios"
    >
      <div className="py-4"></div>
      <div className="px-4  mx-0 mx-sm-4">
        <div>
          <div className="d-flex ms-3">
            <h2 className="title segment-title">Servicios</h2>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-between content-container">
            <div className="col-12 col-sm-12 col-md-6 row d-flex">
              <div>
                <p className="font-20 service-body">
                  Expertos en automatizar la conectividad de tus aplicaciones
                  tradicionales con la nube. Integrar herramientas que aumenten
                  tus métodos de contacto con tus clientes (Omnicanal). Mejorar
                  tu eficiencia con el uso de tecnología.
                </p>
                <ul>
                  <li>Asesoría tecnológica y de arquitectura</li>
                  <li>Procesos de calidad e innovación</li>
                  <li>Asesoría tecnológica y de arquitectura</li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 row d-flex">
              <div className="col-12 col-sm-6 d-flex flex-column">
                <div className="d-flex mb-4">
                  <div className="col-4">
                    <img src={WebIcon} alt="Web" />
                  </div>
                  <div className="col  mx-0 mx-sm-4">
                    <p className="service-body">
                      <strong className=" mb-4">Desarrollo Web</strong>
                    </p>
                    <p className="service-body">
                      Desarrollamos websites públicos, aplicaciones web
                      empresariales.
                    </p>
                  </div>
                </div>
                <div className="d-flex my-4">
                  <div className="col-4">
                    <img src={CloudIcon} alt="Web" />
                  </div>
                  <div className="col  mx-0 mx-sm-4">
                    <p className="service-body">
                      <strong className=" mb-2">Aplicaciones en la nube</strong>
                    </p>
                    <p className="service-body">
                      Escala tu infraestructura y permite tener tu información
                      segura y disponible, siempre.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 d-flex flex-column justify-content-end">
                <div className="d-flex my-4">
                  <div className="col-4 ">
                    <img src={AppIcon} alt="App" />
                  </div>
                  <div className="col  mx-0 mx-sm-4">
                    <p className="service-body">
                      <strong className=" mb-2">Aplicaciones móviles</strong>
                    </p>
                    <p className="service-body">
                      Te ayudamos a crear aplicaciones B2B, B2C en iOS, Android
                      o Híbridas.
                    </p>
                  </div>
                </div>
                <div className="d-flex mt-4">
                  <div className="col-4">
                    <img src={TeamIcon} alt="Team" />
                  </div>
                  <div className="col  mx-0 mx-sm-4">
                    <p className="service-body">
                      <strong className=" mb-2">Equipos extendidos</strong>
                    </p>
                    <p className="service-body">
                      Permítenos extender tu equipo con experto en tecnología
                      diseño y programación.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-4"></div>
    </div>
  );
};

export default Services;
