import React from "react";
import ProyectSlider from "../../components/ProyectSlider";
import ImageSet from "../../components/ImageSet";
import proyects from "../../../resources/proyects";

const Proyects = () => {
  console.log(proyects);
  return (
    <div className="cont-1 p-0 cont pt-4 w-100" id="proyectos">
      <div className="py-4"></div>
      <div className="w-100">
        <div className="d-flex flex-column px-4 mx-0 mx-sm-4 ">
          <div className="d-flex ms-3">
            <h2 className="title segment-title">Casos de éxito</h2>
          </div>
          <div>
            <ProyectSlider data={proyects} />
          </div>
          <div>
            <ImageSet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Proyects;
