import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Modal } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../../../config";
import emailjs from "@emailjs/browser";

const { SERVICE_ID, PROJECT_KEY, TEMPLATE_ID, SITE_KEY } = config;

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [captchaToken, setCaptchaToken] = useState(null);
  const [show, setShow] = useState(false);
  const [sent, setSent] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);

  const onChange = (value) => {
    setCaptchaToken(value);
  };

  const onSubmit = (data) => {
    setLoading(true)
    console.log({ ...data, "g-recaptcha-response": captchaToken });
    emailjs
      .send(
        SERVICE_ID,
        TEMPLATE_ID,
        { ...data, "g-recaptcha-response": captchaToken },
        PROJECT_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          reset();
          setSent(true);
        },
        (error) => {
          setSent(false);
          console.log(error.text);
        }
      )
      .finally(() => {
        handleShow()
        setLoading(false)
      });
  };

  return (
    <Form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="client_name">
        <Form.Label className="align-self-start">Nombre</Form.Label>
        <Form.Control
          required
          placeholder="Nombre"
          {...register("client_name")}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="client_email">
        <Form.Label className="align-self-start">Email</Form.Label>
        <Form.Control
          required
          placeholder="Email"
          type="email"
          {...register("client_email")}
        />
        <Form.Group className="mb-3" controlId="client_subject">
          <Form.Label className="align-self-start">Asunto</Form.Label>
          <Form.Control
            required
            placeholder="Asunto"
            {...register("client_subject")}
          />
        </Form.Group>
      </Form.Group>
      <Form.Group className="mb-3" controlId="message">
        <Form.Label className="align-self-start">Mensaje</Form.Label>
        <Form.Control
          required
          placeholder="Mensaje"
          {...register("message")}
          as="textarea"
          style={{ height: "8rem" }}
        />
      </Form.Group>
      <div className="w-100 d-flex flex-column flex-sm-row justify-content-between align-items-center align-items-sm-start">
        <div className="d-flex">
          <ReCAPTCHA
            sitekey={SITE_KEY}
            onChange={onChange}
            style={{ transform: "scale(0.8);", transformOrigin: "0 0" }}
          />
        </div>
        <Button
          disabled={!captchaToken || loading}
          type="submit"
          className="text-white px-3 h-fit mt-3 mt-sm-0"
        >
          {!loading ? (
            "Enviar"
          ) : (
            <div class="spinner-border spinner-border-sm text-light" role="status">
            </div>
          )}
        </Button>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header
          closeButton
          className={`${sent ? "bg-success" : "bg-danger"} text-white`}
        >
          <Modal.Title>{sent ? "Éxito" : "Error"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {sent ? (
              <p>
                Mensaje enviado ¡Gracias por considerarnos para su proyecto!
              </p>
            ) : (
              <p>
                Hubo un error intentando enviar el mensaje. Por favor vuelva a
                intentarlo.
              </p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export default ContactForm;
