import React from "react";
import "./styles.scss";

import HPFP from "../../../assets/profiles/Hinojosa.png"
import LPFP from "../../../assets/profiles/Lujan.png"
import RPFP from "../../../assets/profiles/Rodriguez.png"
import QPFP from "../../../assets/profiles/qa42.png"

const profiles = [
    {
        name: "Luis Lujan",
        pfp: LPFP,
        position: "CEO",
        description: '“En Petenz queremos que tu empresa suba del escalón tecnológico en el que se encuentra ahora a uno más alto.”'
    },
    {
        name: "Samuel Hinojosa",
        pfp: HPFP,
        position: "DEVELOPMENT LEADERSHIP",
        description: '“Deja tu proyecto en manos de los expertos”',
    },
    {
        name: "Daniel Rodriguez",
        pfp: RPFP,
        position: "PROJECT MANAGER",
        description: '“Creamos un sistema de gestión de proyectos en un marco de metodologías Ágiles”',
    },
    {
        name: "Andrea Arellano",
        pfp: QPFP,
        position: "QUALITY ASSURANCE",
        description: '“Desarrollamos proyectos con estándares de calidad óptimos para la industria global”',
    },
]

const Team = () => {
  return (
    <div className="pb-4 p-0 cont pt-4" id="equipo">
      <div className="py-4"></div>
      <div className="px-4 mx-0 mx-sm-4">
        <div>
          <div className="d-flex justify-content-center ms-3">
            <h2 className="title segment-title">Nuestro equipo</h2>
          </div>
          <div
            className="d-flex justify-content-between row"
          >
            {profiles.map((profile) => {
                return (
                    <div className="profile-card col-12 col-md-5 col-lg-3 col-xl  d-flex flex-column p-4 mx-0 mx-sm-3 my-4  align-items-start">
                        <div className="my-4 mx-3">
                            <img src={profile.pfp} alt={profile.name} />
                        </div>
                        <div className="mx-3 p-0 my-0">
                            <p className="name-text m-0">{profile.name}</p>
                            <p className="position-text my-2">{profile.position}</p>
                        </div>
                        <div className="mx-3 p-0 my-2">
                            <p className="description-text">{profile.description}</p>
                        </div>
                    </div>
                )
            })}
          </div>
        </div>
      </div>

      <div className="py-4"></div>
    </div>
  );
};

export default Team;
