import React from "react";
import "./styles.scss";
import ContactForm from "./ContactForm";

import ContactIcon from "../../../assets/icons/Rectangle.svg";
import CardImage from "../../../assets/images/image-1.png";
import { Button } from "react-bootstrap";

const ContactUs = () => {
  return (
    <div className="pb-4 p-0 cont pt-4 adaptable-container" id="contacto">
      <div className="py-4"></div>
      <div className="px-4 mx-0 mx-sm-4">
        <div className="d-flex flex-column flex-lg-row">
          <div className="d-flex flex-column flex-sm-row flex-lg-column col-12 col-lg-6 justify-content-center">
            <div className="contact-img-container variable-width align-self-md-center my-3">
              <img className="contact-image" src={CardImage} alt="" />
              <div className="white-mask" />
            </div>
            <div className="address-data variable-width align-self-start align-self-sm-center py-2 ms-0 ms-sm-3 ms-lg-0 ">
              <p className="font-barlow">
                Parque Tecnológico ITESO, <br />
                <p className="justified-text">
                  Independencia 1018, Haciendas de San José, 45609 San Pedro
                  Tlaquepaque, Jalisco. Edificio 1, piso 2, Oficina 203.
                </p>
              </p>
              <Button href="mailto:info@petenz.com" variant="link" id="email" className="font-barlow text-primary text-start p-0">info@petenz.com</Button>
            </div>
          </div>
          <div className="d-flex flex-column col-12 col-lg-6">
            <div className="d-flex title-container align-items-center">
              <div className="img-container d-flex align-items-center justify-content-center">
                <img height={30} width={30} src={ContactIcon} alt="" />
              </div>
              <div>
                <h2 className="title segment-title p-0 m-0">Contáctanos</h2>
              </div>
            </div>
            <ContactForm />
          </div>
        </div>
      <div className="py-4 my-4"></div>
      </div>
    </div>
  );
};

export default ContactUs;
