import React from "react";

import PETENZ from "../../../assets/icons/PETENZ.svg";
import FacebookIcon from "../../../assets/icons/facebook.svg";
import LinkedinIcon from "../../../assets/icons/linkedin.svg";
import TwitterIcon from "../../../assets/icons/twitter.svg";

function Footer() {
  return (
    <div className="footer d-flex flex-column flex-md-row align-items-center">
      <div className="mb-1 me-md-auto ">
        <img src={PETENZ} alt="Petenz" />
      </div>
      <div>
        <p
          className="p-0 m-0 font-14 copyright"
        >
          © 2023 Petenz. Todos los derechos reservados.
        </p>
      </div>
      <div className="d-flex">
        <div className="mx-3">
          <a
            href="https://www.facebook.com/profile.php?id=100083451432269&mibextid=ZbWKwL"
            target="_blank"
            rel="noreferrer"
          >
            <img src={FacebookIcon} alt="Our Facebook" />
          </a>
        </div>
        <div className="mx-3">
          <a
            href="https://www.linkedin.com/company/petenz/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LinkedinIcon} alt="Our Linkedin" />
          </a>
        </div>
        <div className="mx-3">
          <a
            href="https://twitter.com/Petenz_UCS"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TwitterIcon} alt="Our Twitter" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
