import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./styles.scss";

import BG1 from "../../../assets/images/proy_background_1.png";
import BG2 from "../../../assets/images/proy_background_2.png";
import BG3 from "../../../assets/images/proy_background_3.png";
import chevronLeft from "../../../assets/icons/chevron-left-solid.svg";
import chevronRight from "../../../assets/icons/chevron-right-solid.svg";
import Image1 from "../../../assets/images/Imagen1.png";
import Image2 from "../../../assets/images/Imagen2.png";

const imageRoulete = [BG2, BG3, BG1];

const SliderCard = (props) => {
  const { data } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div
      className="proyect-card p-0 mx-0 mx-md-3 my-3 my-md-0"
      style={{
        backgroundImage: `url(${imageRoulete[props.index % 3]})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        className={`d-grid h-100 w-100 card-screen-${
          (props.index % 3) + 1
        } col m-0 p-2 px-md-5 py-md-4`}
      >
        <div className="content-category-cont">
          <p className="content-category mt-2 mb-0 tag-text">{data.category}</p>
        </div>
        <div className="d-flex flex-column content-description-container align-items-start">
          <b className="content-description-title text-start">{data.name}</b>
          <br />
          <p className="d-none d-sm-flex content-description-body">
            {data.description}
          </p>
        </div>
        <div className="d-flex flex-column align-items-start align-self-end mb-2">
          <Button
            className="text-white content-description-body px-0"
            onClick={handleShow}
            variant="link"
          >
            <b>Read more</b>
          </Button>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><div>
            <div className="modal-logo-container">
              <img height={"60%"} src={Image2} alt="petenz-logo" />
              <img className="mt-3" height={"60%"} src={Image1} alt="petenz" />
            </div>
            </div></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div >
            <div>
              <p className="modal-title">{data.name}</p>
            </div>
            <div>
              <p className="modal-text"><strong>Cliente:</strong> {data.details.client}</p>
            </div>
            <div>
              <p className="modal-text">{data.details.introduction}</p>
            </div>
            <div>
              <p className="modal-text"><strong>Desafío:</strong> {data.details.challenge}</p>
            </div>
            <div>
              <p className="modal-text"><strong>Solución:</strong> {data.details.solution}</p>
            </div>
            <div>
              <p className="modal-text"><strong>Estrategia:</strong> {data.details.strategy}</p>
            </div>
            <div>
              <p className="modal-text"><strong>Resultado:</strong> {data.details.results}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const ProyectSlider = (props) => {
  const { data } = props;
  // const _array = ["", "", ""];
  return (
    <div className="slider-container">
      <div className="d-flex justify-content-end align-items-center mx-2">
        <Button disabled variant="outline-primary" className="square-button">
          <img className="button-icon" src={chevronLeft} alt="<" />
        </Button>
        <Button disabled variant="outline-primary" className="square-button">
          <img className="button-icon" src={chevronRight} alt=">" />
        </Button>
      </div>
      <div className="d-flex flex-column flex-md-row carrousel col-12 justify-content-between mx-0 mx-sm-2">
        {data.length > 0 &&
          data.map((proyect, i) => {
            console.log(proyect);
            return <SliderCard key={i} data={proyect} index={i} />;
          })}
      </div>
    </div>
  );
};

export default ProyectSlider;
