const data = [
  {
    name: "SuperTech",
    category: "APP",
    description:
      'La tienda en línea ya tiene una plataforma web, la empresa quiere llegar a más clientes a través de una aplicación móvil. La aplicación debe permitir a los clientes buscar productos fácilmente, ver detalles sobre cada producto y realizar pagos en línea de manera segura.',
    details: {
      client: 'Tienda en línea "SuperTech"',
      introduction: '',
      challenge: 'La tienda en línea "SuperTech" se enfrenta a la necesidad de aumentar sus ventas y llegar a una audiencia más amplia. Aunque la tienda en línea ya tiene una plataforma web, la empresa quiere llegar a más clientes a través de una aplicación móvil. La aplicación debe permitir a los clientes buscar productos fácilmente, ver detalles sobre cada producto y realizar pagos en línea de manera segura.',
      solution: 'Desarrollar una aplicación móvil que sirva como un catálogo de productos de la tienda en línea "SuperTech". La aplicación permitirá a los clientes buscar productos, ver detalles y comprar en línea. La aplicación móvil también redireccionará a los usuarios a la página web de la tienda para completar sus transacciones de pago.',
      strategy: 'La aplicación móvil de "SuperTech" debe ser fácil de usar e intuitiva. Debe incluir una interfaz clara y atractiva que permita a los clientes buscar productos en diferentes categorías y subcategorías. La aplicación móvil también debe incluir imágenes de alta calidad de cada producto y detalles importantes, como precios, especificaciones técnicas y disponibilidad. \n\n Además, la aplicación móvil debe permitir a los usuarios agregar productos a su carrito de compras y luego redireccionarlos a la página web de la tienda en línea para completar su compra. La página web debe tener un proceso de pago seguro que acepte diferentes formas de pago, incluyendo tarjetas de crédito y débito, transferencias bancarias y billeteras electrónicas. \n\n Para fomentar las compras repetidas, la aplicación móvil también debe incluir una sección de "Productos similares" que muestre productos relacionados con los que ya han comprado los clientes.',
      results: 'La aplicación móvil de "SuperTech" ha sido un éxito para la tienda en línea. La aplicación móvil ha ayudado a aumentar las ventas y ha atraído a nuevos clientes a la tienda en línea. La interfaz intuitiva y clara de la aplicación móvil ha facilitado la búsqueda de productos para los clientes. La opción de comprar en línea ha sido muy conveniente y ha mejorado la experiencia del usuario. La sección de recomendaciones ha ayudado a fomentar las compras repetidas y ha aumentado el ticket promedio por compra. En general, la aplicación móvil ha mejorado la satisfacción del cliente y ha llevado a una mayor lealtad del cliente.',
    },
  },
  {
    name: "El Hogar",
    category: "APP",
    description:
      'Aunque la mueblería tiene una base de clientes fieles, la competencia ha aumentado en los últimos años y la empresa necesita encontrar nuevas formas de atraer a los clientes y mantenerse relevante en el mercado.',
    details: {
      client: 'Mueblería "El Hogar"',
      introduction: '',
      challenge: 'La mueblería "El Hogar" se enfrenta a la necesidad de aumentar sus ventas y llegar a un público más amplio. Aunque la mueblería tiene una base de clientes fieles, la competencia ha aumentado en los últimos años y la empresa necesita encontrar nuevas formas de atraer a los clientes y mantenerse relevante en el mercado.',
      solution: 'Desarrollar una aplicación móvil que permita a los clientes de la mueblería buscar, seleccionar y comprar muebles y accesorios de decoración desde la comodidad de sus teléfonos móviles. Utilizando un ERP externo fácil de utilizar y con el que el cliente estaba familiarizado. ',
      strategy: 'La aplicación móvil debe ser fácil de usar y ofrecer una experiencia de compra sin problemas para los clientes. Debe tener una interfaz intuitiva y atractiva que permita a los clientes buscar y encontrar fácilmente los muebles y accesorios que necesitan. También debe incluir imágenes de alta calidad y detalladas de cada artículo para que los clientes puedan ver los productos desde diferentes ángulos y tener una idea clara de cómo se verían en sus hogares. \n\n Además, la aplicación debe permitir a los clientes realizar pedidos en línea y pagar de manera segura utilizando diferentes métodos de pago, incluyendo tarjetas de crédito y débito, transferencias bancarias y billeteras electrónicas. \n\n La aplicación también debe incluir una sección de "ofertas especiales" que muestre los productos que tienen descuentos y promociones especiales, y una sección de "novedades" que muestre los nuevos productos que se agregan a la tienda. \n\n Al crear la cuenta se puede seleccionar la ocupación del cliente, lo cual implica un porcentaje de descuento dependiendo de la ocupación del usuario, esto para beneficiar a clientes como diseñadores o afines que utilizan bastante el servicio de la mueblería.',
      results: 'La aplicación móvil ha sido un gran éxito para la mueblería "El Hogar". Los clientes pueden ahora realizar pedidos desde cualquier lugar y en cualquier momento, lo que ha aumentado significativamente el número de ventas y ha atraído a nuevos clientes. La aplicación también ha mejorado la satisfacción del cliente al proporcionar una experiencia de compra fácil y sin problemas. La sección de ofertas especiales y novedades ha ayudado a mantener a los clientes informados sobre las últimas promociones y productos nuevos.',
    },
  },
  {
    name: "Chocolatera",
    category: "APP",
    description:
      "Chocolatera S.A. es una empresa de fabricación y distribución de chocolates, en uno de sus modelos de negocio realizan la toma de pedidos de sus clientes, y vía su red de distribuidores realizan la entrega de sus productos.",
    details: {
      client: 'CHOCOLATERA',
      introduction: 'Chocolatera S.A. es una empresa de fabricación y distribución de chocolates, en uno de sus modelos de negocio realizan la toma de pedidos de sus clientes, y vía su red de distribuidores realizan la entrega de sus productos. \n\n Su red de distribuidores actualmente se maneja en muchos de los casos con poca o ninguna tecnología. Motivo por lo Chocolatera ha decidido construir una solución que permita a su red de distribuidores hacer más eficientes sus operaciones y a su vez, tener una mejor visibilidad de mercado de sus productos, inventarios, etc. \n\n La solución consistió en personalizar un sistema de planificación de recursos empresariales (ERP) de uso libre llamado "ARISTA" para gestionar sus procesos internos. Sin embargo, ahora tienen la necesidad de integrar este sistema ERP Libre con su sistema SAP, para lo cual han decidido utilizar una comunicación FTP.',
      challenge: 'El equipo de gestión de PETENZ identificó la necesidad de mejorar su sistema de ventas en ruta, lo que implicaba la necesidad de personalizar el ERP de uso libre y construir una aplicación móvil que pudiera conectarse con este, proporcionando así a los distribuidores una herramienta tecnológica y de ventas en tiempo real. \n\n Tanto el ERP como la aplicación móvil debían ser económicos y fácil de usar, confiables y seguros, y donde se debía permitir la toma de pedidos, seguimiento de entregas y generación de informes de ventas',
      solution: 'Para resolver este desafío, PETENZ decidió diseñar en conjunto con Chocolatera el flujo de negocio a personalizar en el ERP y de construir la aplicación móvil de ventas en ruta que pudiera conectarse con el ERP en tiempo real o bien de manera asíncrona.  \n\n EL equipo de desarrollo WEB, se fue responsable de la personalización del ERP, manteniendo los procesos estándar de las operaciones empresariales, pero adecuándolo a la necesidad de la red de distribuidores de Chocolatera.\n\n El equipo de desarrollo de aplicaciones móviles se encargó de diseñar e implementar la aplicación móvil personalizada que se conectara con el sistema ERP Libre ARISTA. La aplicación permitiría a los representantes de ventas realizar pedidos en tiempo real, hacer seguimiento de entregas, generar informes de ventas y recibir actualizaciones en tiempo real sobre los pedidos y entregas.\n\n La aplicación móvil se construyó utilizando las últimas tecnologías de desarrollo de aplicaciones móviles y se integró con el sistema ERP Libre a través de una API personalizada. La aplicación también se aseguró de cumplir con los estándares de seguridad y privacidad, incluyendo la encriptación de datos y el acceso restringido a la información de ventas.',
      strategy: '',
      results: 'La implementación exitosa tanto del ERP para los procesos operativos, así como de la aplicación móvil de ventas en ruta ha mejorado significativamente la eficiencia de los distribuidores de Chocolatera. Esto ha mejorado la toma de decisiones y la precisión de la información, lo que ha reducido la posibilidad de errores y ha aumentado la satisfacción del cliente. \n\n Además, la integración de la aplicación móvil con el sistema ERP Libre ha mejorado la colaboración y la eficiencia en los procesos empresariales los distribuidores. Ahora, la empresa puede transferir datos entre la aplicación móvil y el sistema ERP Libre de manera segura y confiable, lo que ha mejorado la precisión de la información y ha reducido la posibilidad de errores. \n\n En general, la implementación de la solución del ERP Libre y aplicación móvil han mejorado significativamente la competitividad de los distribuidores en el mercado al permitir una mayor eficiencia en los procesos empresariales y una mayor satisfacción del cliente.',
    },
  },
];

export default data;
