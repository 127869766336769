import React from "react";
import Image from 'react-bootstrap/Image'

import "./styles.scss"

import LogoFerrero from "../../../assets/images/ferrero.png"
import LogoAbsa from "../../../assets/images/absa.png"
import LogoJalisco from "../../../assets/images/jalisco.png"
import LogoNike from "../../../assets/images/nike.png"

const proyectLogos = [
    {name: "jalisco", image: LogoJalisco},
    {name: "absa", image: LogoAbsa},
    {name: "nike", image: LogoNike},
    {name: "ferrero", image: LogoFerrero},
]

function ImageSet() {
  return (
    <div className="p-2 my-4">
      <div className="mx-2 my-4 d-flex row justify-content-around w-100">
        {proyectLogos.map((logo) => (
            <div className="d-flex col-6 col-sm justify-content-center align-items-center">
                <img className="logo-image" src={logo.image} alt={logo.name}/>
            </div>
        ))}
      </div>
    </div>
  );
}

export default ImageSet;
