import React from "react";
import PetenzLogo from "../../../assets/icons/petenz_logo.png";
import Button from "react-bootstrap/Button";

const Header = ({}) => {
  return (
    <div className="header d-flex flex-column flex-md-row">
      <div className="d-flex flex-row justify-content-between align-items-center col-12">
        <div>
          <img src={PetenzLogo} alt="petenz - ultra connected systems" />
        </div>
        <div className="d-none d-md-flex flex-row justify-content-between align-items-center">
          <div className="tab_content">
            <a href="#proyectos">
              <b>Proyectos</b>
            </a>
          </div>
          <div className="tab_content">
            <a href="#servicios">
              <b>Servicios</b>
            </a>
          </div>
          <div className="tab_content">
            <a href="#servicios">
              <b>Soluciones</b>
            </a>
          </div>
          <div className="tab_content">
            <a href="#equipo">
              <b>Equipo</b>
            </a>
          </div>
        </div>
        <div>
          <Button
            className="bg-PetenzBtn text-white"
            variant="primary"
            href="#contacto"
          >
            Contáctanos
          </Button>
        </div>
      </div>
      <div className="d-flex d-md-none flex-row justify-content-between align-items-center mt-3 col-12">
        <div className="tab_content">
          <a href="#proyectos">
            <b>Proyectos</b>
          </a>
        </div>
        <div className="tab_content">
          <a href="#servicios">
            <b>Servicios</b>
          </a>
        </div>
        <div className="tab_content">
          <a href="#soluciones">
            <b>Soluciones</b>
          </a>
        </div>
        <div className="tab_content">
          <a href="#equipo">
            <b>Equipo</b>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
