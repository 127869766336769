import React from "react";
import { Button } from "react-bootstrap";

function Home() {
  return (
    <div className="container container-home p-0 cont-0 col-12" id="home">
      {/* <div className="cont-0_over h-100 d-flex justify-content-center"> */}
      <div className="d-flex h-100 w-100 col-6 flex-column justify-content-center align-items-center">
        <div>
          <h1 className="title">
            <b>
              Soluciones digitales <br />
              que impulsan a las empresas
            </b>
          </h1>
        </div>
        <div>
          <p>Desarrollo tecnológico a la medida para ti y tu negocio</p>
        </div>
        <div className="d-flex flex-row justify-content-center">
          <Button
            variant="primary px-4 me-3"
            className="text-white"
            href="#servicios"
          >
            Soluciones
          </Button>
          <Button variant="outline-light px-4 ms-3" href="#equipo">
            Experiencia
          </Button>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Home;
